import React from 'react'
import { Redirect } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { isWinter } from '../utils/isWinter'

const IndexPage = () => {
	const { winterDate, zomerDate } = useStaticQuery(graphql`
		query {
			winterDate: wordpressPage(title: { eq: "Winter" }) {
				acf {
					start_datum
				}
			}
			zomerDate: wordpressPage(title: { eq: "Zomer" }) {
				acf {
					start_datum
				}
			}
		}
	`)

	return (
		<Redirect
			noThrow
			to={`/${
				isWinter(winterDate.acf.start_datum, zomerDate.acf.start_datum)
					? 'winter'
					: 'zomer'
			}`}
		/>
	)
} // highlight-line

export default IndexPage
