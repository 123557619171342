import { isBefore } from 'date-fns'

export const isWinter = (winter, zomer) => {
	//January is 0
	//Feb is 1
	//Sept is 8
	// const testDateSummer = new Date(2020, 4, 1)
	// const testDateWinter = new Date(2020, 0, 1)

	// Add check if page contains:
	// /winter ? return true
	// or
	// /summer ? return false

	if (
		typeof window !== 'undefined' &&
		window.location.href.includes('winter')
	) {
		return true
	}
	if (
		typeof window !== 'undefined' &&
		window.location.href.includes('zomer')
	) {
		return false
	}
	if (
		typeof window !== 'undefined' &&
		((window.location.href.includes('mountainbike') &&
			!window.location.href.includes('schaats') &&
			!window.location.href.includes('#mountainbike')) ||
			(window.location.href.includes('skate') &&
				!window.location.href.includes('schaats') &&
				!window.location.href.includes('#skate')) ||
			(window.location.href.includes('ardennen') &&
				!window.location.href.includes('schaats') &&
				!window.location.href.includes('#ardennen')) ||
			(window.location.href.includes('skeeler') &&
				!window.location.href.includes('schaats') &&
				!window.location.href.includes('#skeeler')))
	) {
		return false
	}
	const today = Date.now()

	return (
		(isBefore(today, new Date(winter)) &&
			isBefore(today, new Date(zomer))) ||
		(!isBefore(today, new Date(winter)) &&
			!isBefore(today, new Date(zomer)))
	)
}
